@charset "utf-8";

@font-face {
  font-family: 'HangeulNuri-Bold';
  src: local('HangeulNuri-Bold'), url('./fonts/HangeulNuri-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'paybooc-Bold';
  src: local('paybooc-Bold'), url('./fonts/paybooc-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NanumSquareRound';
  src: local('NanumSquareRound'), url('./fonts/NanumSquareRound.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'yg-jalnan';
  src: local('yg-jalnan'), url('./fonts/JalnanOTF00.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'BMJUA';
  src: local('BMJUA'), url('./fonts/BMJUA.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MalgunGothic'; 
  src: local('MalgunGothic'), url('./fonts/MalgunGothic.eot');
  font-weight: normal;
  font-style: normal;
}

/* 일본어 */
@font-face {
  font-family: 'craftmincho';
  src: local('craftmincho'), url('./fonts/jp-craftmincho.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'KosugiMaru-Regular';
  src: local('KosugiMaru-Regular'), url('./fonts/jp-KosugiMaru-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RampartOne-Regular';
  src: local('RampartOne-Regular'), url('./fonts/jp-RampartOne-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ReggaeOne-Regular';
  src: local('ReggaeOne-Regular'), url('./fonts/jp-ReggaeOne-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'saruji';
  src: local('saruji'), url('./fonts/jp-saruji.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TsunagiGothic';
  src: local('TsunagiGothic'), url('./fonts/jp-TsunagiGothic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'yokomoji';
  src: local('yokomoji'), url('./fonts/jp-yokomoji.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  background-color: white;
}

.react-flow__arrowclosed {
  width: 100px !important;
  height: 100px !important;
}

* {

  font-family: sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",  "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 스크롤 스타일 줄때*/ 
.scroll::-webkit-scrollbar {
  width: 10px;
}
    
.scroll::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  outline: 0;
}
h2 {
  line-height: 115%;
}